<template>
  <div class="ConfidentialityPolitique" >
    <div class="container">
      <div class="row text-center mt-4">
        <div class="col-12">
          <h2 class="help-title">Politique de confidentialité</h2>
          <em> En vigueur au 2 septembre 2020 </em>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            A. Introduction
          </h3>
          <p>
            La confidentialité des visiteurs de notre site web est très importante
            à nos yeux, et nous nous engageons à la protéger.
            Cette politique détaille ce que nous faisons de vos informations personnelles.
          </p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            B. Collecte d’informations personnelles
          </h3>
          <p>
            Les types d’informations personnelles suivants peuvent être collectés et utilisés :
            <ul>
              <li>
                Des informations à propos de votre ordinateur, y compris votre adresse IP,
                le type et la version de votre navigateur, et votre système d’exploitation ;
              </li>
              <li>
                Les informations saisies lors de la création de votre profil (nom, prénom, coordonnées, etc.) ;
              </li>
              <li>
                Votre mot de passe n'est pas stocké en clair sur nos serveurs, seul le hash de votre mot de passe est stocké. Nous utilisons
                l’algorithme PBKDF2 avec une fonction de hachage SHA256, un mécanisme d’étirement de mot de passe recommandé par le NIST ;
              </li>
              <li>
                Les documents que vous nous transmettez et qui permettent d'instruire votre dossier ;
              <li>
                Les informations contenues dans toutes les communications que vous nous envoyez
                par e-mail ou sur notre site web, y compris leurs contenus et leurs métadonnées ;
              </li>
              <li>
                Toute autre information personnelle que vous nous communiquez.
              </li>
            </ul>
          </p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            C. Utilisation de vos informations personnelles
          </h3>
          <p>
            Les informations personnelles fournies par le biais de
            notre site web seront utilisées dans les objectifs décrits dans cette
            politique ou dans les pages du site pertinentes.
            Vos informations sont seulement utilisées pour :
            <ul>
              <li>Administrer notre site web ; </li>
              <li>Personnaliser votre interface ; </li>
              <li>Instruire votre dossier par un conseiller Audiens ; </li>
              <li>Maintenir la sécurité de notre site web et empêcher la fraude ; </li>
              <li>Vérifier le respect des conditions générales qui régissent l’utilisation de notre site web </li>
            </ul>
          </p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            D. Divulgation de vos informations personnelles
          </h3>
          <p>
            Vos informations personnelles ne seront accessibles qu’aux personnes strictement
            habilitées à les traiter. Votre conseiller Audiens pourra télécharger vos documents et
            sauvegarder vos données pour mener à bien sa mission. Nous pouvons divulguer vos
            informations personnelles :
            <ul>
              <li>
                Dans la mesure où nous sommes tenus de le faire en vertu de la législation applicable ;
              </li>
              <li>
                Dans le cadre de toute procédure judiciaire en cours ou à venir ;
              </li>
              <li>
                Pour établir, exercer ou défendre nos droits légaux (y compris fournir des informations à d’autres à des
                fins de prévention des fraudes) ;
              </li>
              <li>
               A toute personne que nous estimons raisonnablement faire partie intégrante d’un tribunal ou autre autorité compétente pour la divulgation de ces informations personnelles si,
               selon notre opinion, un tel tribunal ou une telle autorité serait susceptible de demander la divulgation de ces informations personnelles.
              </li>
            </ul>
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            E. Stockage et transfert de données
          </h3>
          <p>
            Les informations que nous collectons sont stockées et utilisées en France.
            Vos informations ne seront en aucun cas transférées ou stockées dans un autre pays que la France.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            F. Conservation de vos informations personnelles
          </h3>
          <p>
            Cette section détaille nos politiques de conservation des données et nos procédures,
            conçues pour nous aider à nous conformer à nos obligations légales concernant la
            conservation et la suppression d’informations personnelles. Les données vous concernant sont
            conservées pour la durée strictement nécessaire à la gestion de votre dossier, puis jusqu’à
            expiration des délais légaux de prescription applicables.
          </p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            G. Sécurité de vos informations personnelles
          </h3>
          <p>
            Nous prendrons les précautions techniques et organisationnelles nécessaires pour
            empêcher la perte, l’abus ou l’altération de vos données personnelles.
            Nous stockerons toutes les informations personnelles que vous nous fournissez sur
            des serveurs sécurisés (protégés par mot de passe et pare-feu).
            Tous les transferts d'informations et documents effectués par le biais de notre site
            web seront protégés par des technologies de cryptage.
            Vous êtes responsable de la confidentialité du mot de passe que vous utilisez pour
            accéder à notre site web ; nous ne vous demanderons pas votre mot de passe (sauf
            quand vous vous identifiez sur notre site web).
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            H. Amendements
          </h3>
          <p>
            Nous nous réservons le droit de modifier unilatéralement et à tout moment le
            contenu des présentes. Vous devez vérifier cette page régulièrement pour vous
            assurer de prendre connaissance de tout changement.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            I. Vos droits
          </h3>
          <p>
            Vous disposez, sur les données vous concernant et à condition de justifier de votre
            identité, de droits d’accès, de rectification et d’effacement des données, d’opposition
            et de limitation du traitement, ainsi que du droit à la portabilité de vos données, dans
            les conditions et limites prévues par la réglementation applicable.
            Ces droits peuvent être exercés auprès du Délégué à la protection des données

            <ul>
              <li>Par courrier électronique adressé à dpo@audiens.org ;</li>
              <li>Par courrier postal adressé à DPO - Groupe Audiens, 74 rue Jean Bleuzen, 92170 VANVES.</li>
            </ul>
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            J. Sites web tiers
          </h3>
          <p>
            Notre site web contient des liens hypertextes menant vers des sites web
            tiers et des informations les concernant. Nous n’avons aucun
            contrôle sur ces sites, et ne sommes pas responsables de leurs
            politiques de confidentialité ni de leurs pratiques.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            K. Cookies
          </h3>
          <p>
            Ce Site web stocke certaines informations fournies par l'Utilisateur en local. A aucun
            moment, les données stockées sur le navigateur de l'Utilisateur ne sont envoyées au
            serveur sans son accord (lorsque l'Utilisateur choisit d'envoyer ses données au
            serveur en cliquant le bouton à cet effet). Ces données sont stockées pour permettre
            l'utilisation du Site par l'Utilisateur :
            <ul>
              <li>
                L’ID de session permettant à l’Utilisateur de rester connecté sur le Site. Par
                mesure de sécurité, ces cookies sont inutilisables au bout de sept (7) jours et
                l’Utilisateur devra se reconnecter
              </li>
              <li>
                Le profil d'Utilisateur pour adapter et personnaliser le Site à ses besoins
              </li>
            </ul>
            Ces données stockées ne sont jamais envoyées vers le serveur sans le consentement
            de l'Utilisateur et ne sont pas utilisées pour tracker l'Utilisateur. Par ailleurs,
            l'Utilisateur peut supprimer les données stockées sur son navigateur à tout moment
            en passant par les options.
          </p>
          <p>
            Le Site utilise des cookies uniquement dans un objectif de mesure d'audience, via l'outil Google Analytics.
          </p>
          <p>
            L’Utilisateur peut s’opposer à l’enregistrement des cookies en configurant son
            navigateur en cliquant sur l’un des liens suivants :
            <ul>
              <li>Pour Internet Explorer™ : <a href="http://windows.microsoft.com/fr-FR/windows-vista/Blockor-allow-cookies" target="_blank">http://windows.microsoft.com/fr-FR/windows-vista/Blockor-allow-cookies</a></li>
              <li>Pour Safari™ : <a href="http://support.apple.com/kb/ht1677" target="_blank">http://support.apple.com/kb/ht1677</a></li>
              <li>Pour Chrome™ : <a href="http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647" target="_blank">http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647</a>,</li>
              <li>Pour Firefox™ : <a href="http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies" target="_blank">http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies</a></li>
              <li>Pour Opera™ : <a href="http://help.opera.com/Windows/10.20/fr/cookies.html" target="_blank">http://help.opera.com/Windows/10.20/fr/cookies.html</a></li>
            </ul>
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'ConfidentialityPolitique',
}
</script>
